.finance {
  background-image: url('./image4.webp');
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
#loginButton {
  position: absolute;
  right: 0;
  height: 30vh;
  /* border: solid 1px black; */
  width: 50vw;
  cursor: pointer;
}