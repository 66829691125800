.ecommerce {
    background-image: url('./e-commerce-troub.webp');
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
  }
  #loginButton {
    position: absolute;
    right: 0;
    height: 30vh;
    /* border: solid 1px black; */
    width: 50vw;
    cursor: pointer;

    /* border: solid 1px black; */
  }