#card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid 1px black; */
    margin-top: 100px;
    height: 40vh;
    flex-direction: column;
}
#card-container img {
    width: 400px;
}
#card-container p {
    font-family:'Times New Roman', Times, serif;
    font-size: 10px;
    font-weight: bold;
}