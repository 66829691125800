#account-profile-container {
    color: black;
    /* border: solid 3px green; */
    height:100%;
    width:100%;
    /* color: white; */
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
}
/* #account-profile-container div {
    border: solid 1px black;
} */
#card-info {
    flex-grow: 7;
    display: flex;
    flex-direction: row;
}
#card-tos {
    flex-grow: 1;
}
#card-form {
    flex-grow: 3;
    /* border: solid 4px black; */
}
#card-pic {
    flex-grow:1;
}