#tokenContainer {
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#tokenContainer div {
    /* border: solid 1px black; */
    flex-grow: 1;
}
#tokenContainer h3 {
    color: darkslategrey;
    letter-spacing: 2px;
    font-weight: normal;
}
#tokenDisplay {
    /* border: solid 10px red;   */
    width: 50%;
    
}
#tokenExplaination {
    width: 50%;
}
#tokenExplanationPre {
    line-height: 3;
}
#tokenTextArea {
    height: 60vh;
    width: 95%;
    border: solid 1px #ccc;
}

textarea, pre {
    width: 95%;
    text-overflow: wrap;
    padding: 20px;
    line-height: 3; 
    resize: none;
    background-color: #FAFAFA;
    color: grey;
    white-space: pre-wrap;
    word-wrap: break-word;
    /* min-height: 100% !important; */
    margin: 0;
    font-size: 14px;
    text-align: left;
    height: 60vh;
    border: solid 1px #ccc;
    border-radius: 20px;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }