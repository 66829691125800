#tenant-container {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100vh;
}
#tenant-title {
    /* border: 1px red solid; */
    margin: 80px 0 100px 0;
    font-size: 3.5rem;
    text-transform:none;

}

#tenant-input-container {
    /* border: 1px green solid; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 20%;
}

#tenant-input {
    font-size: 1.8rem;
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px white solid;
    outline: none;
    width: 50vw;
    text-align: center;
    padding: 10px;
    /* margin-top: 100px; */
}

#tenant-enter-btn {
    width: 25%;
    font-size: 1.8rem;
    align-self: center;
    background-color: transparent;
    color: white;
    outline: none; 
    border: 1px white solid;
    border-radius: 20px;
    /* margin-top: 50px; */
}

#tenant-enter-btn:hover {
    color: black;
    background-color: white;
    cursor:grab
}