#general-form-container {
    background-color: #fafafa;
    border: solid 1px #ccc;
    height:100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;  
    justify-content: flex-start; 
    padding:10px; 
    color: darkgray;
}
#form-header {
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-bottom:10px;
}
#general-name {
    display: flex;
    flex-direction: row;
    width:100%;
}
#input-group {
    display: flex;
    flex-direction: column;
    /* border: solid 1px green; */
    flex-grow: 1;
    padding: 10px;
    margin-top: 10px;
}
#input-group input {
    height: 5vh;
    border-radius: 10px;
    padding: 10px;
    border: solid 1px lightgray;
    color: gray;
}
#input-group label {
    align-self: flex-start;
    margin-left: 7px;  
    margin-bottom: 5px;
    font-size: 14px;
}
#general-demographic {
    display: flex;
    flex-direction: row;
}
#gender-dropdown {
    height: 5vh;
    border-radius: 10px;
    border: solid 1px lightgray;
    padding: 10px;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    color: gray;

}
.city-state {
    display: flex;
    flex-direction: row;
}
#input-group-city-state {
    display: flex;
    flex-direction: row;
    /* border: solid 1px green; */
    flex-grow: 1;
    padding: 10px;
    margin-top: 10px;
}
#input-group-city-state input {
    height: 5vh;
    border-radius: 10px;
    padding: 10px;
    border: solid 1px lightgray;
    width: 98%;
    color: gray;
}
#input-group-city-state div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
}
#input-group-city-state div label{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
    margin-left: 5px;
}
#save-form {
    display: flex;
    justify-content: flex-end;
    height: 10vh;
    /* border:solid 1px black; */
    margin-top:10px;
}
#save-form button {
    width: 100px; 
    height:30px;   
    margin-left: 5px;
    background-color: #00247d;
    opacity:.6;
    color: white;
    border-radius: 5px;
}