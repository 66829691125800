.init-form-main {
    /* border: 1px green solid; */
    display: flex;
    flex-direction: column;
    width: 50vw;
}

#init-form-title {
    text-transform: none;
    font-size: 3rem;
}

#init-form-subtitle {
    text-align: left;
    font-size: 1.8rem;
}

#init-form-field-container {
    /* border: 1px red solid; */
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 25px;
}

.init-form-label {
    font-size: 2rem;
    display: none;
    
}
.init-form-input {
    font-size: 1.8rem;
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px white solid;
    outline: none;
}
.init-form-input:focus {
    
}


#init-form-submit-btn {
    width: 25%;
    font-size: 1.8rem;
    align-self: center;
    background-color: transparent;
    color: white;
    outline: none; 
    border: none;
}

#init-form-submit-btn:hover {
    cursor:grab;
    color: blue;
}