#profile-container {
    /* width: 100vw !important; */
    /* border: 1px red purple; */
    background-color: white;
 
}  

#top-navbar {
    /* border: 1px maroon solid; */
    /* background: maroon; */
    position: absolute;
    top: 0;
    right: 0;
    width: 85vw !important;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: solid 1px lightGray;
    background-color: white;

}
.top-nav-item {
    color: darkgrey;
    margin-right:30px;
    align-self: center;
}

#left-navbar {
    /* border: 1px navy solid; */
    /* background: navy; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* justify-content: center; */
    background-color: white;
}
#left-nav-divs {
    border-right: solid 1px lightGray;
    height:100vh;
    border-top: solid 1px lightGray;
}
#left-navbar::-webkit-scrollbar {
    display: none;
}


#left-child-div {
    /* border: 1px orange solid; */
    height:90px;
    padding:30px;
    color: darkgrey;
    letter-spacing: 1px;
    /* font-weight: bold */
}
#left-child-div:hover {
    background-color: #F5F5F5
}

#main-view {
    /* border: 1px green solid; */
    /* background: green; */

    position: absolute;
    bottom: 0;
    right: 0;
    width: 85vw;
    height: 90vh;

}
#business-logo {
    /* border: solid 1px white; */
    height:10vh;
    min-height: 10vh;
    /* background-image: url('https://media.istockphoto.com/id/1345681613/vector/creative-people-logo-vector-illustration-design-editable-resizable-eps-10.jpg?s=612x612&w=0&k=20&c=9XUHICA1ljbxBcLw8ERp0kDDxLNQ8Bp2yR4aUSS6SBs='); */
    background-size: cover;
    justify-content: center;
    color: black;
    background-color: white;
    padding: 10px;
    /* border-bottom: solid 1px lightGray; */
}
#business-logo img {
    /* border: solid 1px green; */
    width:80%;
    height:80%;
    margin-top: 10px;
}

#logout-icon {
    font-size: 18px;
    margin-top: 2px;
}
/* .selectedLink {
    background-color: red;
} */